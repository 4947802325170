import React from "react";
import "./Header.css";

export default function Header() {
    return (
        <header>
            <div className="topbar flex">
                <span className="center">
                    <b>FAKE WORDLE</b>
                </span>
            </div>
        </header>
    );
}
